import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/silver-bullet-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1985 werewolf movie, Silver Bullet"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 15</h1>
                    <h2>Silver Bullet</h2>
                    <h3>August 21, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Daniel Attias // <b>Starring:</b> Corey Haim &amp; Everett McGill</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/15-Silver-Bullet-w-guest-Julia-Marchese-e28b3vb" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 15: Silver Bullet"></iframe>
                        </div>
                        <p><Link to="/transcripts/silver-bullet">Read the episode transcript</Link></p>
                        <p>Bryan and Dave are joined this week to close out their Stephen King Rules! series by Stephen King superfan, Julia Marchese, to talk about the picture that ended the Dino De Laurentis/Stephen King dynasty in the 1980's, Silver Bullet. It stars Corey Haim before he was the teen idol of the 1980's as well as Everett McGill and Gary Busey in a picture where it's kids vs. a werewolf.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=n1dClCykQys" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=n1dClCykQys⁠</OutboundLink></p>
                        <p>Julia Marchese on Twitter: <OutboundLink href="https://twitter.com/juliacmarchese" target="_blank" rel="noreferrer">⁠https://twitter.com/juliacmarchese⁠</OutboundLink></p>
                        <p>Julia's podcast, The Horror Movie Survival Guide: <OutboundLink href="https://podcasts.apple.com/us/podcast/horror-movie-survival-guide/id1260663982" target="_blank" rel="noreferrer">⁠https://podcasts.apple.com/us/podcast/horror-movie-survival-guide/id1260663982</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-baby">Next Episode</Link></li>
                        <li><Link to="/episodes/maximum-overdrive">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)